.content-item {
  display: inline-block;
  width: 100%;
  position: relative;

  .content-title {
    @include GreyLLSubPanNormal1421;
    margin-bottom: 16px;
    letter-spacing: 5px;
    max-width: 120px;
  }

  .content-text-before {
    @include GreyLLSubPanNormal1317;
    margin-bottom: 30px;
  }

  .content-text {
    @include GreyLLSubPanNormal1417;
    margin-bottom: 60px;

    .italic {
      @include GreyLLWebItalic1417;

      &.greek {
        @include GreyLLSubPanNormal1317;

      }
    }
  }

  &.active {
    color: $blue;

    .content-player-bottom {
        .play-button {
          background-image: url("./img/play-btn-bold_blue.svg");
    
          &.paused {
            background-image: url("./img/pause-btn_blue.svg");
          }
        }
    
        .close-button {
          background-image: url("./img/close-btn_blue.svg");
        }
      }
  }

  &#content-item-4, &#content-item-6, &#content-item-8, &#content-item-10, &#content-item-11, &#content-item-12, &#content-item-14, &#content-item-19 {

    .content-object-shape {
      width: 80%;

      @include mq($from: desktop) {
        width: calc(80% - 60px);
      }
    }
  }

  &#content-item-17, &#content-item-18, &#content-item-20 {

    .content-object-shape {
      width: 60%;

      @include mq($from: desktop) {
        width: calc(60% - 60px);
      }
    }
  }

  &#content-item-18 {
    .content-object-shape {
      height: 250px;
    }
  }

  &#content-item-22 {

    .content-object-shape {
      

      @include mq($from: desktop) {
        width: calc(120% - 60px);
      }
    }
  }
}


