.content-player {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;

  .content-player-top {
    position: relative;
  }

  .content-object-shape {
    margin-bottom: 16px;
    margin-right: 30px;
    cursor: pointer;

    @include mq($from: desktop) {
      margin-right: 60px;
    }

    &.content-object-shape-active {
      visibility: hidden;

      &.active {
        visibility: visible;
      }
    }

    &.content-object-shape-active {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      visibility: hidden;

      &.active {
        visibility: visible;
      }
    }
  }

  .content-player-mid {
    height: 3px;
    display: block;
    position: relative;
    margin-bottom: 0;

    @include mq($from: desktop) {
      margin-bottom: 8px;
    }

    .content-player-progressbar {
      display: none;
      height: 1px;
      background-color: $black;
      position: absolute;
      bottom: 0;
    }

    .content-player-bar {
      width: 100%;
      display: block;
      height: 1px;
      background-color: $black;
      position: absolute;
      bottom: 0;
    }
  }

  .content-player-bottom {
    height: 44px;
    background-color: white;
    width: 88px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: -12px;

    @include mq($from: desktop) {
      margin-left: 0;
      height: 24px;
    }

    .button {
      display: block;
      width: 44px;
      height: 44px;
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: center;
      cursor: pointer;

      @include mq($from: desktop) {
        background-size: 20px;
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    .play-button {
      background-image: url("./img/play-btn-bold.svg");
      background-size: 18px;

      &.paused {
        background-image: url("./img/pause-btn.svg");
        background-size: 16px 16px;
      }
    }
    .close-button {
      background-image: url("./img/close-btn.svg");
    }
  }

  &.active {
    .content-player-progressbar {
      display: block;
      height: 3px;
      background-color: $blue;
    }

    .content-player-bottom {
      .play-button {
        background-image: url("./img/play-btn-bold_blue.svg");

        &.paused {
          background-image: url("./img/pause-btn_blue.svg");
        }
      }
      .close-button {
        background-image: url("./img/close-btn_blue.svg");
      }
    }
  }
}
