.focus-object {
  background-color: $blue;
  mix-blend-mode: multiply;
  position: fixed;
  top: 44px;
  left: 0;
  right: 0;
  bottom: 44px;
  z-index: 999;
}

.focus-object-image {
  position: fixed;
  z-index: 9999;
  top: 44px;
  left: 0;
  right: 0;
  bottom: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.focus-close-object {
  display: none;
  background-image: url("./img/close-btn_white.svg");
  background-position: center center;
  bottom: 10px;
  right: 10px;
  position: absolute;
  width: 24px;
  height: 24px;
  background-size: 20px;
  background-repeat: no-repeat;
  z-index: 9999999;
  cursor: pointer;
}

.app-mode-interactive {
  .focus-close-object {
    display: none;
  }
}