.onboarding {
  position: fixed;
  top: 44px;
  left: 0;
  right: 0;
  bottom: 44px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./img/interactive_start_bg.jpg");
  background-blend-mode: multiply;
  background-color: $blue;
}

.onboarding-notice {
  background-color: $offwhite;
  width: 250px;
  height: auto;
  padding: 30px;
  border-radius: 10px;
  @include GreyLLSubPanNormal1821;
  text-align: center;

  .start-button {
    display: inline-block;
  }
}
