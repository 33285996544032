.player {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 44px;
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .player-left {
    height: 44px;
    width: calc(100% - 88px);
    position: relative;
    overflow: hidden;

    .player-text-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      @include GreyLLSubPanNormal1621;
      white-space: nowrap;
      height: 44px;

      .player-text {
        padding-left: 7px;

        &.animated {
          animation: 60s linear 0s 1 normal none running textSlide;
        }

        &.paused {
          animation-play-state: paused;
        }

        .title {
          display: inline-block;
          text-transform: uppercase;
          margin-left: 10vw;
          margin-right: 16px;
        }

        .italic {
          display: inline-block;
          @include GreyLLWebItalic1621;
        }

        br {
          display: none;
        }
      }

      .spacer {
        display: inline-block;
        width: 15px;
        height: auto;
      }

      .break-spacer {
        display: inline-block;
        width: 70px;
        height: auto;
      }
    }

    .player-progressbar {
      left: 0;
      bottom: 0;
      position: absolute;
      height: 4px;
      background-color: $black;
    }
  }

  .player-right {
    height: 44px;
    border-left: 2px solid $black;
    background-color: white;
    width: 88px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .button {
      display: block;
      width: 44px;
      height: 44px;
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
    }
    .play-button {
      background-image: url("./img/play-btn.svg");

      &.paused {
        background-image: url("./img/pause-btn.svg");
        background-size: 16px 16px;
      }
    }
    .close-button {
      background-image: url("./img/close-btn.svg");
    }
  }
}

@keyframes textSlide {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(calc(-100% + 70vw - 88px));
  }
}
