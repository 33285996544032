.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 0;
  background-color: $white;

  .header-title {
    @include GreyLLSubPanNormal1621;
    color: $black;

    a {
      color: $black;
    }
  }

  .header-lang-select {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .lang {
      @include GreyLLSubPanNormal1621;
      width: 34px;
      height: 44px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //padding-left: 8px;
      cursor: pointer;

      &.active {
        text-decoration: underline;
      }

      .long {
        display: none;
      }

      &:nth-child(2) {
        padding-left: 0;
        //padding-right: 8px;
      }
    }
  }
}

.website-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  background-color: $white;
  height: 80px;
  padding-left: 12px;
  padding-top: 10px;
  column-width: 220px;
  column-gap: 10px;
  column-fill: auto;


  .click-me {
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }
  @include GreyLLSubPanNormal1417;

  @include mq($from: tablet) {
    @include GreyLLSubPanNormal1821;
  }

  .header-right {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
    padding-top: 10px;
    padding-right: 12px;
    background-color: $white;

    @include mq($from: desktop-mid) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
    }

    .hide-objects {
      cursor: pointer;
      margin-right: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 8px;

      @include mq($from: desktop-mid) {
        margin-right: 20px;
        margin-top: 0;
      }

      .line {
        display: block;
        height: 1px;
        width: 21px;
        background-color: $black;
        margin-right: 10px;
      }

      .hide,
      .show {
        margin-right: 6px;
        margin-left: 6px;
      }

      .show {
        display: none;
        color: $blue;
      }

      &.hidden {
        color: $blue;

        .show {
          display: block;
        }

        .hide {
          display: none;
        }

        .line {
          background-color: $blue;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .header-lang-select {
    display: flex;
    flex-direction: row;

    .short {
      display: block;

      @include mq($from: desktop) {
        display: none;
      }
    }

    .long {
      display: none;

      @include mq($from: desktop) {
        display: block;
      }
    }

    .lang {
      margin-left: 20px;
      cursor: pointer;

      &.active,
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
