.website-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 75px;
  scroll-behavior: smooth;

  .objects {
    width: 100%;
    position: fixed;
    top: 75px;
    line-height: 0;
    z-index: 1;

    &:hover {
      cursor: url("./img/close-btn_white.svg"), auto;
    }

    img {
      width: 100%;
      height: auto;
    }

    .overlay-objects-all {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &.hidden {
        display: none;
      }
    }

    .focus-object,
    .focus-object-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .overlay-single {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      transition: opacity 250ms;
      max-width: 20%;

      &:hover, &.hover {
        opacity: 1;
      }
    }

    .overlay-single-1 {
      width: 5.6%;
      top: 0;
      left: 50.8%;
      z-index: 9;
    }

    .overlay-single-2 {
      width: 4.3%;
      top: 19.0%;
      left: 54.2%;
      z-index: 9;
    }

    .overlay-single-3 {
      width: 12%;
      top: 8.1%;
      left: 36.6%;
      z-index: 9;
    }

    .overlay-single-4 {
      width: 2.9%;
      top: 73.8%;
      left: 5.6%;
      z-index: 9;
    }

    .overlay-single-5 {
      width: 4.1%;
      top: 52.8%;
      left: 20.6%;
      z-index: 9;
    }

    .overlay-single-6 {
      width: 6.1%;
      top: 49.1%;
      left: 55.0%;
      z-index: 9;
    }

    .overlay-single-7 {
      width: 7.2%;
      top: 54%;
      left: 49.3%;
      z-index: 9;
    }

    .overlay-single-8 {
      width: 3%;
      top: 15.3%;
      left: 75.6%;
      z-index: 9;
    }

    .overlay-single-9 {
      width: 12.2%;
      top: 73.4%;
      left: 72.3%;
      z-index: 9;
    }

    .overlay-single-10 {
      width: 6.2%;
      top: 36.7%;
      left: 1.8%;
      z-index: 9;
    }

    .overlay-single-11 {
      width: 2.4%;
      top: 59.6%;
      left: 38.5%;
      z-index: 9;
    }

    .overlay-single-12 {
      width: 2.1%;
      top: 60.5%;
      left: 14.2%;
      z-index: 9;
    }

    .overlay-single-13 {
      width: 6.6%;
      top: 48.6%;
      left: 28.1%;
      z-index: 9;
    }

    .overlay-single-14 {
      width: 3.9%;
      top: 3.2%;
      left: 87.3%;
      z-index: 9;
    }

    .overlay-single-15 {
      width: 1.8%;
      top: 25.8%;
      left: 69.9%;
      z-index: 9;
    }    

    .overlay-single-16 {
      width: 2.3%;
      top: 7.5%;
      left: 80.45%;
      z-index: 9;
    }
    
    .overlay-single-17 {
      width: 2%;
      top: 56.8%;
      left: 93.7%;
      z-index: 9;
    }
    
    .overlay-single-18 {
      width: 2.89%;
      top: 9.4%;
      left: 16.75%;
      z-index: 9;
    }
    
    .overlay-single-19 {
      width: 5.2%;
      top: 46.7%;
      left: 63%;
      z-index: 9;
    }
    
    .overlay-single-20 {
      width: 3.45%;
      top: 49.2%;
      left: 87.9%;
      z-index: 9;
    }

    .overlay-single-21 {
      width: 2.4%;
      top: 87.4%;
      left: 21.3%;
      z-index: 9;
    } 
    
    .overlay-single-22 {
      width: 13.7%;
      top: 76.9%;
      left: 57.8%;
      z-index: 9;
    }     
  }

  .content-list {
    padding: 12px;
    column-width: 220px;
    column-gap: 20px;
    //column-count: 6;
    column-count: auto;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }

  .about-content {
    padding: 12px;
    /*column-width: 220px;
    column-gap: 20px;
    column-count: 6;
    max-width: 1486px;*/
    margin-top: 0px;
    width: 100%;

    @include mq($from: desktop) {
      width: 50%;
    }
  }

  .about-content-item {
    break-inside: avoid;
    margin-top: 60px;

    &:first-child {
      margin-top: 0;
    }

    @include mq($from: desktop) {
      break-after: column;
      margin-top: 60px;
    }
  }

  .imprint-content {
    color: #a7a5a5;

    a {
      color: #a7a5a5;
      text-decoration: none;
    }
    .about-content-text {
      @include GreyLLSubPanNormal1417;
    }
  }

  .about-content-title {
    //height: 75px;
    @include GreyLLSubPanNormal1821;
    margin-bottom: 30px;
  }

  .about-content-text {
    display: inline-block;
    @include GreyLLSubPanNormal1821;

    .italic {
      font-style: italic;
      padding-left: 10px;
      display: block;
    }
  }

}

