$font-stack-grey-ll: "GreyLLWeb";
$font-stack-grey-ll-subpan: "GreyLLSubPan";

@font-face {
  font-family: "GreyLLWeb";
  font-style: italic;
  font-weight: 350;
  font-display: swap;
  src: url("./fonts/GreyLLWeb-BookItalic.woff") format("woff2"),
    url("./fonts/GreyLLWeb-BookItalic.woff2") format("woff");
}

@font-face {
  font-family: "GreyLLSubPan";
  font-style: normal;
  font-weight: 350;
  font-display: swap;
  src: url("./fonts/GreyLLSubPan-Book.woff") format("woff2"),
    url("./fonts/GreyLLSubPan-Book.woff2") format("woff");
}
