body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  word-break: break-word;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0;
  font-weight: 350;
  font-family: $font-stack-grey-ll-subpan;
  font-style: normal;
  font-weight: normal;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@mixin GreyLLWebItalic1417 {
  font-family: $font-stack-grey-ll;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  font-weight: 350;
  font-style: italic;
}

@mixin GreyLLWebItalic1421 {
  font-family: $font-stack-grey-ll;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0;
  font-weight: 350;
  font-style: italic;
}

@mixin GreyLLWebItalic1621 {
  font-family: $font-stack-grey-ll;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0;
  font-weight: 350;
  font-style: italic;
}

@mixin GreyLLWebItalic1821 {
  font-family: $font-stack-grey-ll;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0;
  font-weight: 350;
  font-style: italic;
}

@mixin GreyLLSubPanNormal1317 {
  font-family: $font-stack-grey-ll-subpan;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0;
  font-weight: 350;
  font-style: normal;
}

@mixin GreyLLSubPanNormal1417 {
  font-family: $font-stack-grey-ll-subpan;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  font-weight: 350;
  font-style: normal;
}

@mixin GreyLLSubPanNormal1421 {
  font-family: $font-stack-grey-ll-subpan;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0;
  font-weight: 350;
  font-style: normal;
}

@mixin GreyLLSubPanNormal1621 {
  font-family: $font-stack-grey-ll-subpan;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0;
  font-weight: 350;
  font-style: normal;
}

@mixin GreyLLSubPanNormal1821 {
  font-family: $font-stack-grey-ll-subpan;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0;
  font-weight: 350;
  font-style: normal;
}
